<template>
<div>
    <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0"></b-col>
        <b-col cols="12" md="3">

            <v-select v-if="$can('read', 'manager')" v-model="user_id" :reduce="user => user.id" :options="staffOptions" :clearable="false"></v-select>

        </b-col>
        <b-col cols="12" md="3">
            <div class="d-flex align-items-center justify-content-end">
                <flat-pickr v-model="rangeDate" class="form-control mb-1" :config="{ mode: 'single',dateFormat: 'Y-m'}"/>
                <!-- <b-button variant="primary" class="mb-1" @click="exportToExcel()"> <span class="text-nowrap">Export</span> </b-button> -->
            </div>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="9">
            <b-card>
                <b-table ref="selectableTable" selectable :select-mode="selectMode" :items="items" :fields="fields" responsive striped class="mb-0">
                    <template #cell(commission_amount)="data">
                        {{ ( (data.item.amount * data.item.percentage / 100) * effective_pencentage.percentage / 100).toFixed(2) }}
                    </template>

                    <template #cell(target_name)="data">
                        <b-link v-if="data.item.target_type == 'MEMBER'" :to="'/apps/member/edit/'+data.item.target_id+'/'">
                            <b>{{data.item.target_name}}</b>
                        </b-link>
                        <b-link v-if="data.item.target_type == 'STAFF'" :to="'/apps/staff/edit/'+data.item.target_id+'/'">
                            <b>{{data.item.target_name}}</b>
                        </b-link>
                    </template>
                    <template #cell(status_number)="data">
                        <b-badge :variant="status[1][data.value]">
                            {{ status[0][data.value] }}
                        </b-badge>
                    </template>
                </b-table>
            </b-card>
        </b-col>
        <b-col cols="3">
            <b-card>

                <label class="section-label">Commission Details</label>
                <hr>
                    <div class="price-details">

                        <ul class="list-unstyled">
                            <li class="price-detail">
                                <div class="detail-title">
                                    Total Transaction
                                </div>
                                <div class="detail-amt">
                                    {{ items.length }}
                                </div>
                            </li>
                            <li class="price-detail">
                                <div class="detail-title">
                                    Total Amount
                                </div>
                                <div class="detail-amt discount-amt text-success">
                                    ${{ total_amount }}
                                </div>
                            </li>
                            <hr />
                            <li class="price-detail">
                                <div class="detail-title">
                                    Effective Bounds
                                </div>
                                <div class="detail-amt">
                                    ${{ effective_pencentage.lower_bound }}
                                </div>
                            </li>
                            <li class="price-detail">
                                <div class="detail-title">
                                    Effective Percentage
                                </div>
                                <div class="detail-amt">
                                    {{ effective_pencentage.percentage }}%
                                </div>
                            </li>
                        </ul>
                        <hr>
                            <ul class="list-unstyled">
                                <li class="price-detail">
                                    <div class="detail-title detail-total">
                                        Total
                                    </div>
                                    <div class="detail-amt font-weight-bolder">
                                        ${{total_commission.toFixed(2)}}
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </b-card>
                </b-col>
            </b-row>

        </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import {
  BTable, BAvatar, BCard, BCardBody, BLink,BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BInputGroupPrepend,BModal, VBModal} from 'bootstrap-vue'
export default {
  components: {
    moment,
    BCard,
    flatPickr,
    BTable,
    BModal, VBModal,
    BAvatar,
    BLink,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BPagination,
    BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected_status : 'ALL',
      rangeDate: null,
      test_string : null,
      user_id : JSON.parse(localStorage.getItem('userData')),
      staffOptions : [],
      headerTitle: ['#', 'STAFF','CLIENT', 'START AT', 'END AT', 'SALARY','ATTEND','REMARKS'],
      headerVal: ['id', 'display_name','member_name', 'start', 'end', 'salary','attend','remarks'],
      genderOptions: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
      ],
      planOptions: [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ],
      statusOptions: [
        { label: 'GRANT', value: 'GRANT' },
        { label: 'DENY', value: 'DENY' },
      ],
      centerOptions: [],
      salary: 0,
      total_commission : 0,
      effective_pencentage : {},
      isAddNewMemberSidebarActive: false,
      searchString: null,
      total_amount:0,
      perPage: 10,
      startRow: 1,
      endRow: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      fields: [ { key: 'code', label: '#' },{ key: 'amount', label: 'Amount' }, { key: 'percentage', label: 'Percentage' }, { key: 'commission_amount', label: 'Commission' }, { key: 'created_at', label: 'Created at' }],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Grant', 2: 'Deny',
      },
      {
        1: 'light-success', 2: 'light-danger'
      }],
      selectMode: 'single',
      selected: [],
    }
  },
  watch: {
    user_id : {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    rangeDate : {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    selected_status : {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    currentPage: {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    searchString: {
      handler(newValue, oldValue) {
        // console.log("search")
        this.searchString = newValue.toUpperCase()
        this.handleSearch()
      },
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue < 9) {
          this.perPage = 10
        }
        // console.log("search")
        this.handleSearch()
      },
    },
  },
  created() {
    this.user_id = this.user_id.id
    this.get_staff()
    this.get_data()
  },
  methods: {
    get_staff() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/staffs/?perpage=9999&page=1`)
        .then(res => {
          this.staffOptions = res.data.staffs
        })
    },
    exportToExcel() {
    //  console.log(this.rangeDate)
      import('@/@core/Export2Excel').then(excel => {
        const list = this.items
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: 'pt-report',
          autoWidth: true,
          stringifyLongNum:true,
          bookType: 'xlsx'
        })
        this.clearFields()
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          // Add col name which needs to be translated
           if (j === 'salary') {
             return parseFloat(v[j])
           } else {
             return j+v[j]
           }

          return v[j]
        })
      )
    },
    get_data() {
      var splitted = []
      if (this.rangeDate === null) {
        this.rangeDate = moment().format('YYYY-MM')
      } else {
      //  splitted = this.rangeDate.split(" to ");
      }
      this.$http.get(process.env.VUE_APP_API_BASE+`/report/commission/`+this.user_id+`/`+this.rangeDate+`/`)
        .then(res => {
          this.total_commission = 0
          this.total_amount = res.data.total_amount
          this.items = res.data.transactions
          this.effective_pencentage = res.data.effective_pencentage
          this.items.forEach((item, i) => {
            this.total_commission += ((parseInt(item.amount) * parseInt(item.percentage) / 100) * parseInt(this.effective_pencentage.percentage) / 100)
          });

        })
    },
    get_center_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/centers/')
        .then(res => {
          this.centerOptions = res.data.centers
        })
    },
    handleChangePage() {

    //  this.get_data()
    },
    onRowSelected(items) {
      this.selected = items[0]
      console.log(items)
      if (items[0].is_pdf == 'Y') {
        //this.iframe_src = '//www.cloudfit.pro/pdf/INVOICE_'+items[0].code+'.pdf'
        //console.log(this.iframe_src)

      } else {
      //this.iframe_src = process.env.VUE_APP_API_BASE+'/invoice.php?print=n&code='+items[0].id
      }
      //this.transaction_id = items[0].transaction_id
    //  this.$refs['my-modal'].show()
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    handleSearch() {
      // let _print = `The user searched for: ${searching}\n`
      // this.$refs.pre.appendChild(document.createTextNode(_print))
    // this.searchString = searching
    // this.currentx = 1
    // this.get_data()
      this.searchTimeOut()
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        // your code
        this.currentPage = 1
        this.get_data()
      }, 500)
    },
  },
}
</script>
<style>
 .price-detail {
   display: flex;
   justify-content: space-between;
   margin-bottom: 0.75rem;
 }

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
